<template>
  <div>
    <form @submit.prevent="doSearch">
      <div>
        <label for="uid-input">B站UID</label>
        <input id="uid-input" pattern="\d+" v-model="uid">
        <button type="submit">查询</button>
      </div>
    </form>
    <table>
      <tr>
        <th>UID</th>
        <th>用户名</th>
        <th>类型</th>
        <th>个数</th>
        <th>时间</th>
      </tr>
      <tr v-for="guard in guards" :key="''+uid+'-'+guard.time">
        <td>{{ guard.uid }}</td>
        <td>{{ guard.name }}</td>
        <td>{{ guard.type }}</td>
        <td>{{ guard.count }}</td>
        <td>{{ guard.time }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "SearchGuards",
  data() {
    return {
      uid: 0,
      guards: []
    }
  },
  methods: {
    doSearch: async function () {
      let host = (process.env.NODE_ENV === "development") ? "http://localhost:8901" : "/api";
      let resp = await fetch(`${host}/guards/${this.uid}`, {
        method: "POST",
      });
      let body = await resp.json();
      if (body.code !== 0) {
        alert(body.message);
        return;
      }
      this.guards = body.data;
    }
  }
}
</script>

<style scoped>
td {
  padding: 0.2em;
}
</style>