<template>
<div>
  <a href="/guards">上舰记录查询</a>
</div>
</template>

<script>
export default {
  name: "front-page"
}
</script>

<style scoped>

</style>