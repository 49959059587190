import { createRouter, createWebHistory } from 'vue-router'
import SearchGuards from "@/views/SearchGuards";
import Frontpage from "@/views/Frontpage";

const routes = [
  {
    path: '/',
    name: "index",
    component: Frontpage
  },
  {
    path: '/guards',
    name: 'guards',
    component: SearchGuards
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
